export const SUPPORT_LANGUAGES = [
  {
    languageId: "english",
    locale: "en_US",
    name: "English",
    icon: "us",
  },
  {
    languageId: "japanese",
    locale: "ja_JP",
    name: "Japanese",
    icon: "ja_JP",
  },
  {
    languageId: "vietnamese",
    locale: "vi",
    name: "Vietnamese",
    icon: "vn",
  },
];
