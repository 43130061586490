import "./shared/assets/vendors/style";
import "./shared/styles/wieldy.less";

import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { history } from "./common/appRedux/store";
import App from "./shared/containers/App";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";

const NextApp = ({ store }) => {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </ConnectedRouter>
      </I18nextProvider>
    </Provider>
  );
};

export default NextApp;
