import logo from "@assets/images/logo.svg";
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { MENU_NAVIGATION } from "@constants/Menu";
import appSwitchIcon from "@assets/images/icons/app-switch-icon.svg";

const SidebarLogo = () => {
  const menuNavigation = (
    <Menu>
      {MENU_NAVIGATION.map((item) => (
        <Menu.Item key={item.id}>
          <img src={item.icon} alt={item.text} />
          &nbsp;
          <a href={item.url}>{item.text}</a>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="gx-layout-sider-header akc-sider-header">
      <Link to="/" className="gx-site-logo">
        <img src={logo} height="27px" alt="logo" />
      </Link>
      <div className="application-title gx-site-logo">
        <Dropdown
          className="application-nav"
          overlay={menuNavigation}
          trigger={["click"]}
        >
          <a
            href="#nav"
            className="ant-dropdown-link apps-navigation menu"
            onClick={(e) => e.preventDefault()}
          >
            <img alt="switch icon" src={appSwitchIcon} />
          </a>
        </Dropdown>
      </div>
    </div>
  );
};

export default SidebarLogo;
