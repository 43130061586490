import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import User from "./User";
import Notes from "./Notes";
import Common from "./Common";
import Accounts from "./Accounts";
import UserProfile from "./UserProfile";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    user: User,
    auth: Auth,
    accounts: Accounts,
    notes: Notes,
    common: Common,
    userProfile: UserProfile,
  });

export default createRootReducer;
