import { SUPPORT_LANGUAGES } from "@constants/Language";
import React, { useState, useEffect } from "react";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import { Select, Spin } from "antd";
import { withCookies, Cookies, useCookies } from "react-cookie";
const SelectLanguages = (props) => {
  const { Option } = Select;
  const [language, setLanguage] = useState(SUPPORT_LANGUAGES[0].locale);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { cookies } = props;
  const handleToggle = (value) => {
    setDropdownOpen(!dropdownOpen);
    setLanguage(value);
    i18n.changeLanguage(value);
    cookies.set("i18nextLng", value);
  };

  useEffect(() => {
    const defaultLang =
      cookies.get("i18nextLng") || SUPPORT_LANGUAGES[0].locale;
    handleToggle(defaultLang);
  }, []);

  const langMenu = () => (
    <>
      {SUPPORT_LANGUAGES.map((language) => (
        <Option key={language.languageId} value={language.locale}>
          <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
        </Option>
      ))}
    </>
  );

  return (
    <Select
      bordered={false}
      onChange={handleToggle}
      showArrow={false}
      value={language}
    >
      {langMenu()}
    </Select>
  );
};

export default withTranslation()(withCookies(SelectLanguages));
