import React from "react";
import Icon from "@ant-design/icons";

import { ReactComponent as DashboardSVG } from "@assets/images/sidebar/Monitor.svg";
import { ReactComponent as MenuSVG } from "@assets/images/sidebar/Menu.svg";
import { ReactComponent as CategorySVG } from "@assets/images/sidebar/Category.svg";
import { ReactComponent as TrendingSVG } from "@assets/images/sidebar/Trending.svg";
import { ReactComponent as ProjectSVG } from "@assets/images/sidebar/Project.svg";
import { ReactComponent as ResourceSVG } from "@assets/images/sidebar/Resource.svg";
import { ReactComponent as ResourceTypeSVG } from "@assets/images/sidebar/Resource Type.svg";
import { ReactComponent as IntegrationSVG } from "@assets/images/sidebar/Integration.svg";

import identityIcon from "@assets/images/icons/identity-logo.svg";
import expenseIcon from "@assets/images/icons/expense-logo.svg";
import safeIcon from "@assets/images/icons/safe-logo.svg";

export const SIDEBAR_MENU = [
  {
    id: "Asset",
    text: "SIDEBAR.ASSET",
    icon: <Icon component={() => <MenuSVG />} />,
    children: [
      {
        id: "Dashboard",
        text: "SIDEBAR.ASSET.DASHBOARD",
        url: "/asset/dashboard",
        icon: <Icon component={() => <DashboardSVG />} />,
      },
      {
        id: "Trending",
        text: "SIDEBAR.ASSET.TRENDING",
        url: "/asset/trending",
        icon: <Icon component={() => <TrendingSVG />} />,
      },
      {
        id: "Project",
        text: "SIDEBAR.ASSET.PROJECT",
        url: "/asset/project",
        icon: <Icon component={() => <ProjectSVG />} />,
      },
      {
        id: "Resource",
        text: "SIDEBAR.ASSET.RESOURCE",
        url: "/asset/resource",
        icon: <Icon component={() => <ResourceSVG />} />,
      },
    ],
  },
  {
    id: "Configure",
    text: "SIDEBAR.CONFIGURE",
    icon: <Icon component={() => <MenuSVG />} />,
    children: [
      {
        id: "Project",
        text: "SIDEBAR.CONFIGURE.PROJECT",
        url: "/configure/project",
        icon: <Icon component={() => <ProjectSVG />} />,
      },
      {
        id: "Resource_Type",
        text: "SIDEBAR.CONFIGURE.RESOURCE_TYPE",
        url: "/configure/resource-type",
        icon: <Icon component={() => <ResourceTypeSVG />} />,
      },
    ],
  },
];

export const MENU_NAVIGATION = [
  {
    id: "expense",
    text: "Cloud Expense",
    icon: expenseIcon,
    url: process.env.REACT_APP_EXPENSE,
  },
  {
    id: "security",
    text: "Cloud Safe",
    icon: safeIcon,
    url: process.env.REACT_APP_SECURITY_ASSURANCE,
  },
  {
    id: "identity",
    text: "Cloud Identity",
    icon: identityIcon,
    url: process.env.REACT_APP_IDENTITY,
  },
];
