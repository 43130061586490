import React from "react";
import ReactDOM from "react-dom";
import i18n from "i18next";
import NextApp from "./NextApp";
import registerServiceWorker from "./registerServiceWorker";
import { getUserProfile, getCloudAccountByCostCenter } from "@api";
// Add this import:
import { getAccessToken } from "@common/helpers";
import { AppContainer } from "react-hot-loader";
import { authenticationInit, getLoginUserInfo } from "./common/auth";
import configureStore from "./common/appRedux/store";
import {
  loadCurrentUserInfo,
  loadUserProfileDetail,
  selectBU,
  selectCostCenter,
  selectAccount,
  setAccounts,
} from "./common/appRedux/actions";
import { isObservable } from "rxjs";

const store = configureStore({
  user: getLoginUserInfo(),
});

// Wrap the rendering in a function:
const render = (Component) => {
  store.dispatch(loadCurrentUserInfo());
  ReactDOM.render(
    <AppContainer>
      <Component store={store} />
    </AppContainer>,
    document.getElementById("root")
  );
};

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./NextApp", () => {
    render(NextApp);
  });
}

const main = () => {
  // Do this once
  registerServiceWorker();

  // Render once
  render(NextApp);
};

const setHeaders = ({
  defaultBU,
  businessUnits,
  defaultCC,
  costCenters,
  company,
}) => {
  localStorage.setItem("buid", defaultBU?.id || businessUnits[0]?.id);
  localStorage.setItem("costCenterId", defaultCC?.id || costCenters[0]?.id);
  localStorage.setItem("companyId", company.id || "");
};

const setReduxConfig = (data) => {
  const { defaultBU, businessUnits, defaultCC, costCenters } = data;
  loadPermissionFromIdentity(data);

  //load BU Cost Center from Identity
  store.dispatch(selectBU(defaultBU || businessUnits[0]));
  store.dispatch(selectCostCenter(defaultCC || costCenters[0]));

  getCloudAccountByCostCenter(defaultBU?.id || businessUnits[0]?.id).subscribe(
    ({ data }) => {
      store.dispatch(setAccounts(data));
      store.dispatch(selectAccount(data));
    }
  );
};

const loadPermissionFromIdentity = (data) => {
  store.dispatch(loadUserProfileDetail(data));
  /*
  getPermission().subscribe((per) => {
    data["permissions"] = {};
    const pers = per.data?.filter((item) => item.code.startsWith("ops_")) || [];
    pers.forEach((item) => {
      data.permissions[item.buId] = data.permissions[item.buId] || {};
      const code = data.permissions[item.buId][item.code] || [];
      code.push(item.scope);
      data.permissions[item.buId][item.code] = code;
    });
    store.dispatch(loadUserProfileDetail(data));
  });
  */
};

authenticationInit().subscribe(
  (connect) => {
    if (!isObservable(connect) && getAccessToken()) {
      getUserProfile().subscribe(
        ({ data }) => {
          if (data?.id) {
            const { costCenter, businessUnits, language } = data;
            i18n.changeLanguage(language || "en_US");

            const localBuid = localStorage.getItem("buid");
            const localCostCenterId = localStorage.getItem("costCenterId");
            data["defaultBU"] = businessUnits.find((x) => {
              return x.id === localBuid;
            });
            if (!data["defaultBU"]) {
              data["defaultBU"] = businessUnits.find((x) => x.isDefaultBu);
            }
            if (!data["defaultBU"]) {
              data["defaultBU"] = businessUnits[0];
            }

            data["defaultCC"] = costCenter.find(
              (x) => localCostCenterId === x.id
            );
            if (!data["defaultCC"]) {
              data["defaultCC"] = costCenter.find(
                (x) => data["defaultBU"].costCenterId === x.id
              );
            }

            setHeaders(data);
            setReduxConfig(data);

            main();
          }
        },
        () => {}
      );
    }
  },
  // handle initial login error
  (err) => {
    console.error(err);
  }
);
