import { useState, useEffect } from "react";

export function useApi(action, transformData) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const loadData = async () => {
    setLoading(true);

    try {
      const actionObs = await action;

      const subscription = actionObs.subscribe(
        (actionData) => {
          if (transformData) {
            setData(transformData(actionData));
          } else {
            setData(actionData);
          }
        },
        (err) => {
          setError(err);
          setLoading(false);
        },
        () => setLoading(false)
      );
      return subscription;
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    // unwrap subscription object without using async in useEffect body
    // this only works because the promise is already fulfilled
    let sub;
    loadData().then((subscription) => {
      sub = subscription;
    });

    return () => sub && sub.unsubscribe();
    // eslint-disable-next-line
  }, [action]);

  return [data, loading, error];
}
