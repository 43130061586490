export const TIME_MODE = {
  YEARLY: "yearly",
  MONTHLY: "monthly",
  DAILY: "daily",
};

export const MODE = {
  DAILY: "daily",
  MONTHLY: "monthly",
  YEARLY: "year",
};

export const TIME_FORMAT = {
  DATE: "YYYY-MM-DD",
  MONTH: "YYYY-MM",
  YEAR: "YYYY",
};
