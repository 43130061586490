import { toast, ToastContainer } from "react-toastify";
import { notification } from "antd";

export function showSuccessToast(msg) {
  toast.success(msg, {
    position: "bottom-right",
  });
}

export function showErrorToast(msg) {
  toast.error(msg, {
    position: "bottom-right",
  });
}

export function openNotification(type, message) {
  notification[type]({
    message: "Notification",
    description: `${message}`,
  });
}

export { ToastContainer };

export function showSpinner() {
  const spinnerElm = document.getElementById("spinner-elm");
  if (spinnerElm) {
    spinnerElm.style.display = "flex";
  }
}

export function hideSpinner() {
  const spinnerElm = document.getElementById("spinner-elm");
  if (spinnerElm) {
    spinnerElm.style.display = "none";
  }
}
