import Keycloak from "keycloak-js";
import { from, of } from "rxjs";
import { concatMap } from "rxjs/operators";
// import { SSO_CONFIGURES } from "../config";
import { getAccessToken } from "../helpers";
import { parseJwtToken, tokenIsExpired } from "./token";
import {
  AUTH_USER_EMAIL,
  AUTH_USER_NAME,
  AUTH_USER_PREFERRED_USERNAME,
  AUTH_USER_ROLES,
  SSO_ACCESS_TOKEN,
  SSO_REFRESH_TOKEN,
  TOKEN_EXPIRE_BEFORE_SECONDS,
} from "./constants";

const SSO_CONFIGURES = {
  url: process.env.REACT_APP_SSO_URL,
  redirectUri: process.env.REACT_APP_SSO_REDIRECT_URI,
  realm: process.env.REACT_APP_SSO_REALM,
  clientId: process.env.REACT_APP_SSO_CLIENT_ID,
  onLoad: process.env.REACT_APP_SSO_ON_LOAD,
  checkSSO: process.env.REACT_APP_SSO_CHECK_SSO,
  "enable-cors": process.env.REACT_APP_SSO_ENABLE_CORS,
  checkLoginIframeInterval: parseInt(
    process.env.REACT_APP_SSO_CHECK_LOGIN_INTERVAL,
    10
  ),
};

let refreshInteval;
class KeycloakConnect {
  _options = null;
  _instance = null;

  constructor(options) {
    if (options) {
      this._options = options;
    } else {
      this.loadConfig();
    }

    this._instance = Keycloak(this._options);
  }

  loadConfig() {
    this._options = SSO_CONFIGURES;
  }

  init() {
    return new Promise((resolve, reject) => {
      this._instance
        .init({ onLoad: this._options.onLoad, checkLoginIframe: false })
        .then((auth) => {
          if (auth) {
            saveToken(this._instance);
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  checkSSO() {
    let that = this;
    return new Promise((resolve, reject) => {
      this._instance
        .init({
          onLoad: this._options.checkSSO,
          checkLoginIframe: false,
          silentCheckSsoRedirectUri:
            window.location.origin + "/silent-check-sso.html",
        })
        .then((auth) => {
          if (auth) {
            that.refreshToken(-1);
            saveToken(this._instance);
            clearInterval(refreshInteval);
            refreshInteval = setInterval(() => {
              that.refreshToken(TOKEN_EXPIRE_BEFORE_SECONDS);
            }, 10000);
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  refreshToken(seconds = 300) {
    return new Promise((resolve, reject) => {
      this._instance
        .updateToken(seconds) // update before token expire xxx seconds
        .then((refreshed) => {
          if (refreshed) {
            saveToken(this._instance, true);
            console.debug("Token was successfully refreshed");
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((e) => {
          console.error(
            "Failed to refresh the token, or the session has expired"
          );
          this.logout();
          reject(e);
        });
    });
  }

  logout() {
    const { redirectUri } = this._options;
    const pathname = window.location.pathname;
    const currentUrl = redirectUri + "/" + pathname;
    return new Promise((resolve, reject) => {
      localStorage.removeItem(AUTH_USER_ROLES);
      localStorage.removeItem(AUTH_USER_NAME);
      localStorage.removeItem(AUTH_USER_PREFERRED_USERNAME);
      localStorage.removeItem(SSO_ACCESS_TOKEN);
      localStorage.removeItem(SSO_REFRESH_TOKEN);
      localStorage.removeItem("buid");
      localStorage.removeItem("costCenterId");
      localStorage.removeItem("companyId");

      const logoutUrl =
        this._getRealmUrl() +
        "/protocol/openid-connect/logout?redirect_uri=" +
        encodeURIComponent(currentUrl);
      window.location.replace(logoutUrl);

      resolve(true);
    });
  }

  _getRealmUrl() {
    const { url, realm } = this._options;

    if (url.charAt(url.length - 1) === "/") {
      return url + "realms/" + encodeURIComponent(realm);
    } else {
      return url + "/realms/" + encodeURIComponent(realm);
    }
  }
}

export function checkExistToken() {
  const token = getAccessToken();

  return token != null && token.length > 0;
}

export function checkExpiredToken(token) {
  token = token || getAccessToken();
  const tokenObj = parseJwtToken(token);

  return tokenIsExpired(tokenObj);
}

export function saveToken(instanceKeycloak, isRefresh = false) {
  if (instanceKeycloak) {
    const jwtToken = parseJwtToken(instanceKeycloak.token);
    localStorage.setItem(AUTH_USER_ROLES, jwtToken.roles);
    localStorage.setItem(AUTH_USER_NAME, jwtToken.name);
    localStorage.setItem(AUTH_USER_EMAIL, jwtToken.email);

    // Temporarily remove after @ part of email to avoid bug in BE
    // TODO: remove later
    let username = jwtToken.preferred_username;
    if (username.includes("@")) {
      username = username.split("@")[0];
    }
    localStorage.setItem(AUTH_USER_PREFERRED_USERNAME, username);

    localStorage.setItem(SSO_ACCESS_TOKEN, instanceKeycloak.token);
    localStorage.setItem(SSO_REFRESH_TOKEN, instanceKeycloak.refreshToken);
  }
}

export function keycloakLogIn() {
  const keycloakConnect = new KeycloakConnect();
  return from(keycloakConnect.init()).pipe(
    concatMap((successed) => {
      return of(successed);
    })
  );
}

export function keycloakCheckSSO() {
  const keycloakConnect = new KeycloakConnect();
  return from(keycloakConnect.checkSSO());
}

export function keycloakLogOut() {
  const keycloakConnect = new KeycloakConnect();

  return from(keycloakConnect.logout());
}
