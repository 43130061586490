import React from "react";
import lineIcon from "@assets/images/icons/line.svg";
import barIcon from "@assets/images/icons/bar.svg";
import areaIcon from "@assets/images/icons/area.svg";
import awsLogo from "@assets/images/icons/aws.svg";
import azureLogo from "@assets/images/icons/azure.svg";
import gcpLogo from "@assets/images/icons/gcp.svg";
import ibmLogo from "@assets/images/icons/ibm.png";
import vmLogo from "@assets/images/icons/vm.png";

export const HOOK_AUTHORIZATION_TYPE = "HOOK_AUTHORIZATION_TYPE";

export const TARGET_OFFSET = 20;

export const AUTHORIZATION_CODE = {
  TokenMissing: 0,
  TokenExpired: 1,
  PermissionMissing: 2,
};

export const Provider = {
  TOTAL: "total",
  AWS: "aws",
  AZURE: "azure",
  GCP: "gcp",
};

export const CSP = {
  AWS: {
    key: "AWS",
    name: "Aws",
    icon: awsLogo,
    image: <img height="17px" src={awsLogo} alt={"Aws"} />,
    identityType: "accountId",
  },
  AZURE: {
    key: "AZURE",
    name: "Azure",
    icon: azureLogo,
    image: (
      <img
        height="17px"
        style={{ marginBottom: "5px" }}
        src={azureLogo}
        alt={"Azure"}
      />
    ),
    identityType: "subscriptionId",
  },
  GCP: {
    key: "GCP",
    name: "Gcp",
    icon: gcpLogo,
    image: (
      <img
        height="17px"
        style={{ marginBottom: "5px" }}
        src={gcpLogo}
        alt={"Gcp"}
      />
    ),
    identityType: "projectId",
  },
  VM: {
    key: "VM",
    name: "VMWare",
    icon: vmLogo,
    image: (
      <img
        height="17px"
        style={{ marginBottom: "5px" }}
        src={vmLogo}
        alt={"VM"}
      />
    ),
    identityType: "projectId",
  },
  IBM: {
    key: "IBM",
    name: "IBM",
    icon: ibmLogo,
    image: (
      <img
        height="17px"
        style={{ marginBottom: "5px" }}
        src={ibmLogo}
        alt={"IBM"}
      />
    ),
    identityType: "projectId",
  },
};

export const TYPE_CHART = {
  LINE: "line",
  COLUMN: "bar",
  AREA: "area",
  COMPOSE: "compose",
};

export const TypeCharts = [
  {
    label: "Line",
    value: TYPE_CHART.LINE,
    icon: lineIcon,
  },
  {
    label: "Column",
    value: TYPE_CHART.COLUMN,
    icon: barIcon,
  },
  {
    label: "Area",
    value: TYPE_CHART.AREA,
    icon: areaIcon,
  },
];

export const CSPTypes = [
  {
    label: "AWS",
    value: Provider.AWS,
    icon: awsLogo,
  },
  {
    label: "Azure",
    value: Provider.AZURE,
    icon: azureLogo,
  },
  {
    label: "Gcp",
    value: Provider.GCP,
    icon: gcpLogo,
  },
];

export const TRENDING_TYPE = {
  SERVER: "server",
  STORAGE: "db-storage",
  NETWORK: "network",
  CLOUD_SERVICE: "cloud-service",
  CSP: "CSP",
};
export const TrendingTypeOptions = [
  {
    key: TRENDING_TYPE.SERVER,
    value: "Server",
    unit: "",
  },
  {
    key: TRENDING_TYPE.STORAGE,
    value: "Storage",
    unit: "GB",
  },
  {
    key: TRENDING_TYPE.NETWORK,
    value: "Network",
    unit: "GB",
  },
  {
    key: TRENDING_TYPE.CLOUD_SERVICE,
    value: "Cloud Service",
  },
  {
    key: TRENDING_TYPE.CSP,
    value: "CSP",
  },
];

export const CATEGORY_TYPE = {
  SUMMARIZE: "General",
  CLOUD_SERVICE: "Cloud Service",
  CSP: "CSP",
};
export const CategoryTypes = [
  {
    label: CATEGORY_TYPE.SUMMARIZE,
    value: "General",
  },
  {
    label: CATEGORY_TYPE.CSP,
    value: "CSP",
  },
  {
    label: CATEGORY_TYPE.CLOUD_SERVICE,
    value: "Cloud Service",
  },
];
export const CLOUD_TYPE = ["AWS", "AZURE", "GCP"];
export const CLOUD_TYPE_LOWER = ["aws", "azure", "gcp"];
export * from "./ActionTypes";
export * from "./ThemeSetting";
export * from "./Budget";
export * from "./Time";
export * from "./ChartColors";
export * from "./FaultImage";
export * from "./Util";
export * from "./Language";
export * from "./Severity";
export * from "./Common";
