import { AuthHttp } from "@common/services";
import { from, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { V1_IDENTITY_API_URL, V1_API_URL } from "@common/config";
import { openNotification } from "@common/helpers";

export function getUserProfile() {
  let url = "users/profile";
  return from(
    AuthHttp.get(`${V1_IDENTITY_API_URL}/${url}`).catch((e) => {
      //openNotification('error', "Get user's profile error");
    })
  );
}

/*
export function getCloudAccountByCostCenter(businessIds) {
  let url = `business-units/${businessIds}/cloud-accounts?limit=100000`;
  return from(
    AuthHttp.get(`${V1_IDENTITY_API_URL}/${url}`).catch((e) => {
      openNotification("error", "Get cloud account error");
    })
  );
}
*/
export function getCloudAccountByCostCenter(businessIds) {
  let url = `cloud-accounts?apps=CLOUD_EXPENSE,CLOUD_SAFE`;
  return from(
    AuthHttp.get(`${V1_IDENTITY_API_URL}/${url}`).catch((e) => {
      openNotification("error", "Get cloud account error");
    })
  );
}

export function getCloudAccountAWS(
  accountIds,
  Service,
  LookbackPeriodInDays,
  PaymentOption,
  TermInYears,
  OfferingClass,
  InstanceType,
  Region
) {
  let url = `ri/aws/?Service=${Service}${parseCloudAccountIdsParams(
    accountIds
  )}&LookbackPeriodInDays=${LookbackPeriodInDays}&PaymentOption=${PaymentOption}&TermInYears=${TermInYears}`;
  if (OfferingClass) {
    url += `&OfferingClass=${OfferingClass}`;
  }
  if (InstanceType) {
    url += `&InstanceType=${InstanceType}`;
  }
  if (Region) {
    url += `&Region=${Region}`;
  }
  return from(
    AuthHttp.get(`${V1_API_URL}/${url}`).catch((e) => {
      openNotification("error", "Get ri error");
    })
  );
}

export function getCloudAccountAZURE(
  accountIds,
  Scope,
  LookbackPeriodInDays,
  TermInYears,
  Service,
  InstanceType,
  Region
) {
  let url = `ri/azure/?Scope=${Scope}${parseCloudAccountIdsParams(
    accountIds
  )}&LookbackPeriodInDays=${LookbackPeriodInDays}&TermInYears=${TermInYears}&Service=${Service}`;
  if (InstanceType) {
    url += `&InstanceType=${InstanceType}`;
  }
  if (Region) {
    url += `&Region=${Region}`;
  }
  return from(
    AuthHttp.get(`${V1_API_URL}/${url}`).catch((e) => {
      openNotification("error", "Get ri error");
    })
  );
}

const parseCloudAccountIdsParams = (accountIds) => {
  if (accountIds.length === 0) {
    return "&accountIds=";
  }

  let params = "";
  Array.from(accountIds).forEach((e) => (params += `&accountIds=${e}`));
  return params;
};

/**
 * GET /api/cost-centers
 * Get cost center list
 */
export function getCostCenters(companyId) {
  const url = `/cost-centers?companyId=${companyId}`;
  return from(AuthHttp.get(`${V1_API_URL}/${url}`)).pipe(
    map((res) => {
      if (res.data && !res.data.length) {
        openNotification("error", "Cost center list is empty!");
        return [];
      }
      return res.data.map(({ id, name }) => ({ id, name }));
    }),
    catchError((e) => {
      openNotification(
        "error",
        "Occurred error when load cost center list from server!"
      );
      return of([]);
    })
  );
}

/**
 * Update /api/public/business-units/setDefaultBu?buId={buId}&userId={userId}
 *
 * @param userId User Id (Required)
 * @param buId Business Unit Id (Required)
 */
export function setDefaultBU(params) {
  const url = `${V1_IDENTITY_API_URL}/business-units/setDefaultBu`;
  return from(AuthHttp.patch(`${url}`, params)).pipe(
    catchError((e) => {
      const messages = e.response.data.messages;
      openNotification(
        "error",
        messages
          ? messages[0].message
          : "Occurred error when set default bu from server!"
      );
      return of({});
    })
  );
}

/**
 * PUT /api/public/user/profile.
 * Update User Profiles
 */
export function updateUserProfile(body) {
  const url = `${V1_IDENTITY_API_URL}/users/info`;
  return from(AuthHttp.put(`${url}`, body)).pipe(
    map((res) => res.data),
    catchError((e) => {
      openNotification(
        "error",
        e.response.data.messages
          ? e.response.data.messages[0].message
          : "Occurred error when load user information!"
      );
      return of({});
    })
  );
}

/**
 * PUT /change-password
 *
 * @param body
 * {
    "currentPassword":"",
    "newPassword":"",
    "confirmNewPassword":""
  }
*/
export function changePassword(body) {
  const url = `${V1_IDENTITY_API_URL}/users/change-password`;
  return from(AuthHttp.put(`${url}`, body)).pipe(
    catchError((e) => {
      openNotification(
        "error",
        e.response.data.messages
          ? e.response.data.messages[0].message
          : "Occurred error when change password!"
      );
      return of({});
    })
  );
}

export function getUserInfo() {
  const url = `${V1_IDENTITY_API_URL}/users/info`;
  return from(AuthHttp.get(url)).pipe(
    map((res) => res.data),
    catchError((e) => {
      openNotification(
        "error",
        e.response.data.messages
          ? e.response.data.messages[0].message
          : "Occurred error when load user information!"
      );
      return of({});
    })
  );
}

export function addView(name, pageName, settings) {
  const url = `${V1_IDENTITY_API_URL}/views`;
  return from(
    AuthHttp.post(url, {
      name: name,
      appCode: "CLOUD_PORTAL",
      pageName: pageName,
      viewSetting: JSON.stringify(settings),
      default: true,
    })
  ).pipe(map((res) => res.data));
}

export function saveView(viewId, pageName, settings) {
  const url = `${V1_IDENTITY_API_URL}/views/${viewId}`;
  return from(
    AuthHttp.put(url, {
      //name: name,
      appCode: "CLOUD_PORTAL",
      pageName: pageName,
      viewSetting: JSON.stringify(settings),
      default: true,
    })
  ).pipe(map((res) => res.data));
}

export function getViews(pageName) {
  const url = `${V1_IDENTITY_API_URL}/views/${pageName}`;
  return from(AuthHttp.get(url)).pipe(map((res) => res.data));
}

export function delView(viewId) {
  const url = `${V1_IDENTITY_API_URL}/views/${viewId}`;
  return from(AuthHttp.delete(url)).pipe(map((res) => res.data));
}

export function setDefaultView(viewId) {
  const url = `${V1_IDENTITY_API_URL}/views/${viewId}/default-view`;
  return from(AuthHttp.patch(url)).pipe(map((res) => res.data));
}
