import { useTranslation } from "react-i18next";
import { Layout, Alert, Row, Col, Button } from "antd";
import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import App from "@pages";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import i18n from "i18next";
import { getAvaiableNotification } from "@api";
import { useSelector } from "react-redux";
import { formatDate } from "@util";
import { WarningFilled } from "@ant-design/icons";

const { Content } = Layout;

const MainApp = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const [limits, setLimits] = useState();
  const userProfile = useSelector((state) => state.user.detail);

  const LIMIT_TYPES = {
    WORKLOAD_LIMIT_80: 80,
    WORKLOAD_LIMIT_90: 90,
    WORKLOAD_LIMIT_100: 100,
    WORKLOAD_LIMIT_110: 110,
    WORKLOAD_LIMIT_115: 115,
  };
  const EXPRIED_TYPES = {
    TIME_EXPIRE_30: 30,
    TIME_EXPIRE_15: 15,
    TIME_EXPIRE_7: 7,
    TIME_EXPIRE_0: 0,
  };

  const getRemainingDaysFromExpired = (startDate, expiedType) => {
    var today = new Date();
    today.setDate(today.getDate() - expiedType);
    return Math.floor(Math.abs(startDate - today) / 86400000);
  };
  useEffect(() => {
    let subscription = getAvaiableNotification().subscribe((res) => {
      const loggedCompanies = res.data.filter(
        (item) => item.companyId === userProfile.company.id
      );
      if (loggedCompanies.length > 0) {
        setLimits(loggedCompanies);
      }
      return () => subscription.unsubscribe();
    });
  }, [userProfile]);

  return (
    <Layout className="gx-app-layout">
      {/*<Sidebar translate={i18n} />*/}
      <Layout>
        <Topbar />
        {limits &&
          limits.map((item) => {
            if (LIMIT_TYPES[item.notificationType]) {
              return (
                <Alert
                  message={<b>{t("MESS.0022")}</b>}
                  icon={<WarningFilled />}
                  type="error"
                  description={
                    <span>
                      {/*t("MESS.0023")*/}
                      You are reaching {LIMIT_TYPES[item.notificationType]}%
                      your workload limit. Above the limitations, it might cause
                      some problems to your cloud account. Please contact us to
                      extend your service
                    </span>
                  }
                  showIcon
                  action={
                    <Button size="small" type="text">
                      UNDO
                    </Button>
                  }
                  closable
                />
              );
            } else if (EXPRIED_TYPES[item.notificationType]) {
              return (
                <Alert
                  message={<b>Warning</b>}
                  icon={<WarningFilled />}
                  type="error"
                  description={
                    <span>
                      Your plan is about to expire in{" "}
                      {getRemainingDaysFromExpired(
                        new Date(item.startDate),
                        EXPRIED_TYPES[item.notificationType]
                      )}{" "}
                      days. Please update the payment details to renew it
                    </span>
                  }
                  showIcon
                  action={
                    <Button size="small" type="text">
                      UNDO
                    </Button>
                  }
                  closable
                />
              );
            } else {
              return (
                <Alert
                  message={<b>Warning</b>}
                  icon={<WarningFilled />}
                  type="error"
                  description={
                    <span>
                      You plan has expired. Please update the payment details to
                      renew it
                    </span>
                  }
                  showIcon
                  action={
                    <Button size="small" type="text">
                      UNDO
                    </Button>
                  }
                  closable
                />
              );
            }
          })}
        <Content
          className={"gx-layout-content gx-container-wrap "}
          id="body-content"
        >
          <App match={match} translate={i18n} />
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainApp;
