import React from "react";
import { PUBLIC_URL, LANDING_URL } from "@common/config";
import { cleanURL } from "@util";
import asyncComponentLoader from "@util/asyncComponent";
import SafeRoute from "@util/SafeRoute";
import { Route, Switch, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { SSO_ACCESS_TOKEN, SSO_REFRESH_TOKEN } from "@constants/sso/type";

const App = ({ match, translate }) => {
  const asyncComponent = (path, prefix, title) => {
    return asyncComponentLoader(() => import(`${path}`), {
      prefixRoute: cleanURL(`${matchUrl}/${prefix}`),
      title: title,
      translate: translate,
    });
  };

  const matchUrl = cleanURL(`${match.url}${PUBLIC_URL}`);
  //SSO
  const dataSSO = [
    { id: "ifr_identity", url: process.env.REACT_APP_IDENTITY },
    { id: "ifr_security", url: process.env.REACT_APP_SECURITY_ASSURANCE },
    { id: "ifr_expense", url: process.env.REACT_APP_EXPENSE },
    { id: "ifr_ops", url: process.env.REACT_APP_CLOUD_OPERATION },
  ];

  const token = [
    {
      key: SSO_ACCESS_TOKEN,
      value: localStorage.getItem(SSO_ACCESS_TOKEN),
    },
    {
      key: SSO_REFRESH_TOKEN,
      value: localStorage.getItem(SSO_REFRESH_TOKEN),
    },
  ];

  function ifOnload(id, url) {
    if (
      localStorage.getItem(SSO_ACCESS_TOKEN) &&
      localStorage.getItem(SSO_REFRESH_TOKEN)
    ) {
      document.getElementById(id).contentWindow.postMessage(token, `${url}/`);
    }
  }

  return (
    <div className="gx-page-layout portals">
      <Switch>
        <Route path={`${matchUrl}`} exact={true}>
          <Redirect to={cleanURL(`${matchUrl}/dashboard`)} />
        </Route>

        <SafeRoute
          path={cleanURL(`${matchUrl}/dashboard`)}
          component={asyncComponent(
            "./Dashboard",
            "dashboard",
            "SIDEBAR.ASSET.DASHBOARD"
          )}
        />
        <SafeRoute
          path={cleanURL(
            `${matchUrl}/resource/fail/:cloud/:service/:resourceType`
          )}
          component={asyncComponent(
            "./Resource/Fail",
            "resource",
            "SIDEBAR.ASSET.DASHBOARD"
          )}
        />
        <SafeRoute
          path={cleanURL(`${matchUrl}/resource/violations/detail/:id`)}
          component={asyncComponent(
            "./Resource/Violations/Detail",
            "resource",
            "SIDEBAR.ASSET.DASHBOARD"
          )}
        />
        <SafeRoute
          path={cleanURL(`${matchUrl}/resource/violations`)}
          component={asyncComponent(
            "./Resource/Violations",
            "resource",
            "SIDEBAR.ASSET.DASHBOARD"
          )}
        />
        <SafeRoute
          path={cleanURL(`${matchUrl}/resource/detail/:name`)}
          component={asyncComponent(
            "./Resource/Investigate",
            "resource",
            "SIDEBAR.ASSET.DASHBOARD"
          )}
        />
        <SafeRoute
          path={cleanURL(`${matchUrl}/resource`)}
          component={asyncComponent(
            "./Resource",
            "resource",
            "SIDEBAR.ASSET.DASHBOARD"
          )}
        />
        <SafeRoute
          path={cleanURL(`${matchUrl}/resource/license`)}
          component={asyncComponent(
            "./Resource",
            "resource",
            "SIDEBAR.ASSET.DASHBOARD"
          )}
        />
        <SafeRoute
          path={cleanURL(`${matchUrl}/user-profile`)}
          component={asyncComponent("./UserProfile", "user-profile")}
        />
        <SafeRoute
          path={"*"}
          component={() => (window.location.href = `${LANDING_URL}/404`)}
        />
      </Switch>
      {dataSSO &&
        dataSSO.map((item) => {
          return (
            <iframe
              style={{ display: "none" }}
              id={item.id}
              onLoad={() => ifOnload(item.id, item.url)}
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
              src={`${item.url}/getlocalstorage.html`}
            />
          );
        })}
    </div>
  );
};

export default withTranslation("translations")(App);
