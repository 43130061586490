import {
  SET_CLOUD_ACCOUNTS,
  SELECT_CLOUD_ACCOUNT,
  SELECT_PROVIDER,
  SELECT_ALL_CLOUD_ACCOUNT,
  DESELECT_ALL_CLOUD_ACCOUNT,
  CHECKED_ALL_CLOUD_ACCOUNT,
} from "@constants/ActionTypes";

export const setAccounts = (accounts) => {
  return {
    type: SET_CLOUD_ACCOUNTS,
    payload: accounts,
  };
};

export const selectAccountByVendor = (accounts, provider) => {
  return {
    type: SELECT_CLOUD_ACCOUNT,
    payload: { accounts, provider },
  };
};

export const selectAccount = (accounts) => {
  return {
    type: SELECT_ALL_CLOUD_ACCOUNT,
    payload: accounts,
  };
};

export const deselectAllAccount = () => {
  return {
    type: DESELECT_ALL_CLOUD_ACCOUNT,
  };
};

export const selectProvider = (provider) => {
  return {
    type: SELECT_PROVIDER,
    payload: provider,
  };
};

export const checkedAllCloudAccount = (isChecked) => {
  return {
    type: CHECKED_ALL_CLOUD_ACCOUNT,
    payload: isChecked,
  };
};
