const API_URL = process.env.REACT_APP_API_URL;
const V1_API_URL = `${API_URL}`;
const V1_NOTI_API_URL = `${process.env.REACT_APP_NOTI_API_URL}`;
const V1_IDENTITY_API_URL = `${process.env.REACT_APP_IDENTITY_API_URL}/api/v1`;
const V1_IDENTITY_URL = `${process.env.REACT_APP_IDENTITY}`;
const PUBLIC_API_URL = `${API_URL}/api/public`;
const PUBLIC_URL = process.env.PUBLIC_URL;
const LANDING_URL = process.env.REACT_APP_LANDING_URL;

const V1_OPS_API_URL = `${process.env.REACT_APP_API_SEC}/v1`;
const V1_GLOBAL_NOTI_API_URL = `${process.env.REACT_APP_API_NOTI}/api/v1`;
const V1_EXP_API_URL = `${process.env.REACT_APP_API_EXPENSE}`;

const RESOURCE_URL = process.env.REACT_APP_PORTAL;
const REACT_APP_IDENTITY_URL = process.env.REACT_APP_IDENTITY;

const SSO_CONFIGURES = {
  url: process.env.REACT_SSO_CONFIGURES_URL,
  redirectUri: process.env.REACT_SSO_CONFIGURES_REDIRECT_URL,
  realm: "akaclaud-Dev",
  clientId: "akaclaud-labs",
  onLoad: "login-required",
  "enable-cors": true,
  checkLoginIframeInterval: 900,
};

export {
  V1_API_URL,
  V1_NOTI_API_URL,
  PUBLIC_API_URL,
  SSO_CONFIGURES,
  PUBLIC_URL,
  LANDING_URL,
  V1_IDENTITY_API_URL,
  V1_IDENTITY_URL,
  V1_OPS_API_URL,
  V1_EXP_API_URL,
  V1_GLOBAL_NOTI_API_URL,
  RESOURCE_URL,
  REACT_APP_IDENTITY_URL,
};
