import { from } from "rxjs";
import { map } from "rxjs/operators";
import { generateRandomString } from "../helpers";
import { keycloakLogIn, keycloakLogOut, keycloakCheckSSO } from "./keycloak";

export function checkReloadRequire() {
  const storageKeys = Object.keys(localStorage);

  return storageKeys.some((k) => k.indexOf("kc-callback-") >= 0);
}

export function checkRetryLoginRequire() {
  const storageKeys = Object.keys(localStorage);

  return storageKeys.some((k) => k.indexOf("akc_retry_login") >= 0);
}

export function checkRetryLogout() {
  const storageKeys = Object.keys(localStorage);

  return storageKeys.some((k) => k.indexOf("akc_logout") >= 0);
}

export function retryLogin() {
  localStorage.setItem("akc_retry_login", generateRandomString(16));
  return logOut();
}

export function authenticationInit() {
  return from(checkSSO()).pipe(
    map((auth) => {
      if (!auth) {
        return login();
      }
    })
  );
}

export function login() {
  return keycloakLogIn();
}

export function checkSSO() {
  return keycloakCheckSSO();
}

export function logOut() {
  localStorage.setItem("akc_logout", generateRandomString(16));
  return keycloakLogOut();
}

export * from "./constants";
export * from "./guards";
