import moment from "moment";
export function cleanURL(url) {
  if (url == null) {
    return url;
  }

  return url.replace(/\/\//gm, "/");
}

export function roundNumber(number, fixedPoint = 2) {
  if (Number.isNaN(Number.parseFloat(number + ""))) {
    return 0;
  }
  return Math.round(number * fixedPoint * 10) / (fixedPoint * 10);
}

export function formatDate(date) {
  return moment(new Date(date)).format("DD-MM-YYYY");
}

export function getRandomColor(index) {
  const COLORS = [
    "#f79a56",
    "#43a047",
    "#4c87cd",
    "#ea0530",
    "#ff6347",
    "#f0ad4e",
    "#c532a4",
    "#27d7cc",
  ];
  if (index < COLORS.length) {
    return COLORS[index];
  }

  let st = "#";
  for (var i = 0; i < 6; i++) {
    st += "0123456789ABCDEF"[Math.floor(Math.random() * 16)];
  }
  return st;
}
