import XLSX from "xlsx";
import { saveAs } from "file-saver";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

/**
 *
 * @param {*} dataTable
 * @param {*} filename
 * @param {*} aoa : boolean, aoa_to_sheet (true) or json_to_sheet(false)
 * @param {*} headers : array, custome headers row ( default Object.keys )
 */
export const exportToExcel = (dataTable, filename, aoa = true, headers) => {
  const worksheet: XLSX.WorkSheet = aoa
    ? XLSX.utils.json_to_sheet(dataTable)
    : XLSX.utils.aoa_to_sheet(dataTable);

  if (aoa === false && headers) {
    var range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (var C = range.s.r, index = 0; C <= range.e.r; ++C, ++index) {
      var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
      if (!worksheet[address]) {
        continue;
      }
      worksheet[address].v = headers[index];
    }
  }

  const workbook: XLSX.WorkBook = {
    Sheets: { data: worksheet },
    SheetNames: ["data"],
  };
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
  saveAs(
    data,
    filename +
      "_" +
      new Date().toLocaleDateString() +
      "_" +
      new Date().toLocaleTimeString() +
      EXCEL_EXTENSION
  );
};

/**
 *
 * @param {*} filename
 * @param {*} datas<Sheet[]>
 *  Sheet : {
 *  name (string): sheet name,
 *  value (string[] or number[]): value each sheet,
 *  headers (string[]): custom header
 * }
 */
export const exportExcelMultipleSheet = (filename, datas = []) => {
  const sheets = {};
  datas.forEach((d) => {
    sheets[`${d.name}`] = d.value
      ? XLSX.utils.json_to_sheet(d.value, {
          header: d.headers,
          skipHeader: d.headers ? true : false,
        })
      : XLSX.utils.json_to_sheet([{ Error: "Data null" }]);
  });

  const workbook: XLSX.WorkBook = {
    SheetNames: datas.map((d) => d.name),
    Sheets: sheets,
  };

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
  saveAs(data, filename + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
};

// apply column and row filter to data
// returns an array of arrays
export const prepareData = (data, selectedRows, columns, selectedColumns) => {
  let preparedData = data;
  let columTitles = columns.map((col) => col.title);
  if (selectedRows && selectedRows.length > 0) {
    preparedData = selectedRows;
  }
  if (selectedColumns && selectedColumns.length > 0) {
    columTitles = selectedColumns.map((col) => col.title);
    preparedData = preparedData.map((row) =>
      selectedColumns.map((col) => row[col.key])
    );
  }
  return [columTitles, ...preparedData];
};
