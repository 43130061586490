import React, { memo } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";

import MainApp from "./MainApp";

const App = () => {
  const location = useLocation();
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}`} location={location} component={MainApp} />
    </Switch>
  );
};

export default memo(App);
