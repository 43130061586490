import { useTranslation } from "react-i18next";
import { PUBLIC_URL } from "@common/config";
import React, { useState, useMemo } from "react";
import { Button, Layout, Dropdown, Menu, Badge, Col } from "antd";
import UserMenu from "@components/UserMenu";
import { useSelector } from "react-redux";
import { login } from "@common/auth";
import { Link, useLocation } from "react-router-dom";
import {
  BellFilled,
  WarningFilled,
  SettingOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  getNotification,
  markAsReadNotification,
  markAsReadBatchNotification,
} from "@api";
import { useApi } from "@hooks";
import { openNotification } from "@common/helpers";
import { useHistory } from "react-router-dom";
import LanguageSelect from "@components/LanguageSelect";
import logo from "@assets/images/logo.svg";

const { Header } = Layout;

const Topbar = () => {
  const { t } = useTranslation();
  const user = useSelector(({ user }) => user);
  const history = useHistory();

  const location = useLocation();

  const [refresh, setRefresh] = useState(false);
  const userProfile = useSelector((state) => state.user.detail);
  const getNotificationAPI = useMemo(
    async () =>
      getNotification({
        offset: 0,
        limit: 5,
        read: false,
        sort_by: "desc",
        order_by: "created",
      }),
    [refresh]
  );
  const [listNotification] = useApi(getNotificationAPI);
  const markAsRead = (id) => {
    const body = {
      notification_id: id,
      user_id: userProfile.id,
    };
    return markAsReadNotification(body).subscribe(
      (response) => {
        openNotification("success", t("MESS.0024"));
        setRefresh(!refresh);
      },
      (error) => {
        openNotification("error", t("MESS.0025"));
      }
    );
  };

  const markAsReadAll = () => {
    const body = {
      notification_ids: listNotification?.notifications?.map((item) => item.id),
      user_id: userProfile.id,
    };
    return markAsReadBatchNotification(body).subscribe(
      (response) => {
        openNotification("success", t("MESS.0026"));
        setRefresh(!refresh);
      },
      (error) => {
        openNotification("error", t("MESS.0027"));
      }
    );
  };

  const menuKey = ["dashboard", "resource", "cost", "security", "identity"];

  return (
    <div className="akac-header">
      <Header className="akac-header-container">
        <a href="/">
          <img
            height="25px"
            style={{ marginBottom: "5px" }}
            src={logo}
            alt={"logo"}
          />
        </a>
        <Menu
          mode="horizontal"
          selectedKeys={menuKey.filter((item) =>
            location.pathname.includes(item)
          )}
        >
          <Menu.Item key="dashboard">
            <Link to={`${PUBLIC_URL}/dashboard`}>
              {t("SIDEBAR.ASSET.DASHBOARD")}
            </Link>
          </Menu.Item>
          <Menu.Item key="resource">
            <Link to={`${PUBLIC_URL}/resource`}>
              {t("SIDEBAR.ASSET.RESOURCE")}
            </Link>
          </Menu.Item>
          <Menu.Item key="cost">
            <a href={`${process.env.REACT_APP_EXPENSE}/dashboard`}>
              {t("SIDEBAR.ASSET.Cost")}
            </a>
          </Menu.Item>
          <Menu.Item key="security">
            <a
              href={`${process.env.REACT_APP_SECURITY_ASSURANCE}/compliance/standards`}
            >
              {t("SIDEBAR.ASSET.Security")}
            </a>
          </Menu.Item>
          <Menu.Item key="ops">
            <a
              href={`${process.env.REACT_APP_CLOUD_OPERATION}/service-catalog`}
            >
              {t("SIDEBAR.ASSET.Ops")}
            </a>
          </Menu.Item>
          <Menu.Item key="identity">
            <a
              href={`${process.env.REACT_APP_IDENTITY}/user-area/cloud-accounts/aws`}
            >
              {t("SIDEBAR.ASSET.Identity")}
            </a>
          </Menu.Item>
        </Menu>
        <div className="akac-header-right-panel">
          {/*
				{listNotification && (
					<Dropdown
						overlay={
							<Menu className='notification-dropdown'>
								<Menu.Item key={'header'}>
									<Col
										xl={12}
										lg={24}
										md={24}
										sm={24}
										xs={24}
										className='header-left'
									>
										<b>Notification</b>
									</Col>
									<Col
										xl={12}
										lg={24}
										md={24}
										sm={24}
										xs={24}
										className='header-right'
									>
										<small onClick={markAsReadAll}>
											Mark all as read&nbsp;
											<SettingOutlined />
										</small>
									</Col>
								</Menu.Item>
								<Menu.Divider />
								{listNotification.notifications
									.sort(function (a, b) {
										return new Date(b.created) - new Date(a.created);
									})
									.map((e) => {
										const rt = [];
										rt.push(
											<Menu.Item key={e.id} onClick={(value) => {}}>
												<span>
													<span>
														<WarningFilled
															style={{
																fontSize: '13px',
																color: 'rgb(240, 184, 38)',
															}}
														/>
														&nbsp;{' '}
														{e.template_data.SCOPE_TYPE +
															' - ' +
															e.template_data.SCOPE_NAME}
														<CloseOutlined onClick={() => markAsRead(e.id)} />
													</span>
													<b className='block'>{e.titile}</b>
													<code className='block'>{e.message}</code>
													<small className='block datetime'>
														{new Date(e.created).toLocaleDateString('en-GB') +
															' - ' +
															new Date(e.created).toLocaleTimeString()}
													</small>
												</span>
											</Menu.Item>
										);
										rt.push(<Menu.Divider />);
										return rt;
									})}
								<Menu.Item
									onClick={() => history.push('/notification')}
									className='notification-footer'
									key={'footer'}
								>
									<b>See all Notifications</b>
								</Menu.Item>
							</Menu>
						}
						trigger={['click']}
						getPopupContainer={(trigger) => trigger.parentElement}
					>
						<Button className='akac-header-notification'>
							<Badge size='small' count={listNotification.total}>
								{listNotification.notifications.length > 0 ? (
									<BellFilled
										style={{
											fontSize: '18px',
											color: '#f0b45f',
											transformOrigin: '50% 4px',
											animation: 'ring 4s .7s ease-in-out infinite',
										}}
									/>
								) : (
									<BellFilled style={{ fontSize: '18px', color: '#f0b45f' }} />
								)}
							</Badge>
						</Button>
					</Dropdown>
				)}
				<LanguageSelect /> */}
          <UserMenu user={user} />
        </div>
      </Header>
    </div>
  );
};
export default Topbar;
