import { from } from "rxjs";
import { AuthHttp } from "@common/services";
import { V1_EXP_API_URL } from "@common/config";

const parseCloudAccountIdsParams = (accountIds) => {
  if (accountIds.length === 0) {
    return "&cloudAccountIds=";
  }

  let params = "";
  Array.from(accountIds).forEach((e) => (params += `&cloudAccountIds=${e}`));
  return params;
};

export function getAssetDashboard(modeDate, date, selectedAccounts) {
  function toParams(csp) {
    return Array.from(
      selectedAccounts
        .filter((item) => item.csp === csp)
        .map((item) => item.accountUuid)
    ).join(",");
  }
  const awsIds = toParams("AWS");
  const azureIds = toParams("AZURE");
  const gcpIds = toParams("GCP");
  const ibmIds = toParams("IBM");
  return from(
    AuthHttp.get(
      `/api/v1/resources/asset-overviews/${modeDate}?date=${date}&awsIds=${awsIds}&azureIds=${azureIds}&gcpIds=${gcpIds}&ibmIds=${ibmIds}`
    )
  );
}

export function getLastCollectDate() {
  return from(AuthHttp.get(`/api/v1/resources/last-collect-day`));
}

export function getVendorCost(date, mode, cloudAccountIds, start, end) {
  let url = "";
  if (start && end) {
    url = `${V1_EXP_API_URL}/dashboard/vendor?date=${date}&timeType=${mode}&start=${start}&end=${end}${parseCloudAccountIdsParams(
      cloudAccountIds
    )}`;
  } else {
    url = `${V1_EXP_API_URL}/dashboard/vendor?date=${date}&timeType=${mode}${parseCloudAccountIdsParams(
      cloudAccountIds
    )}`;
  }

  return from(AuthHttp.get(url));
}

export function getServiceCost(date, mode, cloudAccountIds) {
  let url = `${V1_EXP_API_URL}/dashboard/service?date=${date}&timeType=${mode}${parseCloudAccountIdsParams(
    cloudAccountIds
  )}`;
  return from(AuthHttp.get(url));
}

export function getBudgetPeriod(modeTime, cloudAccountIds, time) {
  let url = "";
  if (modeTime === "daily") {
    url = `${V1_EXP_API_URL}/budget/cost-daily?date=${time}${parseCloudAccountIdsParams(
      cloudAccountIds
    )}`;
  }
  if (modeTime === "monthly") {
    url = `${V1_EXP_API_URL}/budget/cost-monthly?date=${time}${parseCloudAccountIdsParams(
      cloudAccountIds
    )}`;
  }
  return from(AuthHttp.get(url));
}
