import * as React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { dispatchAuthorizationAction } from "@appRedux/actions";
import { authorValid, AUTHORIZATION_CODE, login } from "../auth";
import { LANDING_URL } from "@common/config";

class SafeRoute extends React.Component {
  componentDidMount() {
    this.checkAuthentication()
      .then(() => {
        this.checkValidAuthor(this.props);
      })
      .catch((e) => {
        console.log("Not authenticated.", this._currentLoginRoles());
      });
  }

  _currentLoginRoles = () => {
    return this.props.user != null ? this.props.user.roles : null;
  };

  checkAuthentication() {
    return new Promise((resolve, reject) => {
      const roles = this._currentLoginRoles();

      if (roles == null) {
        login().subscribe(() => {
          console.log("Redirect login...");
        });

        reject(false);
      } else {
        resolve(true);
      }
    });
  }

  checkValidAuthor(props) {
    const { dispatchAuthorization, roles: expectedRoles } = props;
    const roles = this._currentLoginRoles();
    if (!authorValid(expectedRoles, roles || "")) {
      // navigateTo("/403");
      window.location.href = LANDING_URL + "/403";
      dispatchAuthorization("401", AUTHORIZATION_CODE.PermissionMissing);
    }
  }

  render() {
    const {
      exact,
      path,
      render: childRender,
      component: Component,
    } = this.props;
    const childRenderFunction = (matchProps) =>
      childRender ? childRender(matchProps) : <Component {...matchProps} />;

    return exact ? (
      <Route exact={true} path={path} render={childRenderFunction} />
    ) : (
      <Route path={path} render={childRenderFunction} />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  dispatchAuthorization: dispatchAuthorizationAction,
})(SafeRoute);
