import { from } from "rxjs";
import { AuthHttp } from "@common/services";
import { getRequestParams } from "@helpers";
import { openNotification } from "@common/helpers";
import { V1_NOTI_API_URL } from "@common/config";
import { V1_GLOBAL_NOTI_API_URL } from "@common/config";

export function getNotification(params) {
  return from(
    AuthHttp.get(`${V1_NOTI_API_URL}/notification?${getRequestParams(params)}`)
  );
}

export function markAsReadNotification(body) {
  return from(AuthHttp.put(`${V1_NOTI_API_URL}/notification`, body));
}

export function markAsReadBatchNotification(body) {
  return from(AuthHttp.put(`${V1_NOTI_API_URL}/notification/batch`, body));
}

export function getAvaiableNotification() {
  return from(
    AuthHttp.get(`${V1_GLOBAL_NOTI_API_URL}/notifications/available`)
  );
}
