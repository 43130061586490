import { from } from "rxjs";

import { AuthHttp } from "@common/services";
import { V1_OPS_API_URL } from "@common/config";

export function getAllInventoryAPI(keyword, object) {
  return from(
    AuthHttp.post(
      `${V1_OPS_API_URL}/dashboard/inventory/resource3?keyword=${keyword}`,
      object
    )
  );
}
export function getAllInventoryDetailAPI(cloud, service, object) {
  return from(
    AuthHttp.post(
      `${V1_OPS_API_URL}/dashboard/inventory/resource2?cloud=${cloud}&service=${service}`,
      object
    )
  );
}

export function getTop5InventoryAPI(object) {
  return from(
    AuthHttp.post(
      `${V1_OPS_API_URL}/dashboard/inventory/total2?number=2`,
      object
    )
  );
}

export function getDetailInventoryAPI(cloud, service, object) {
  const url = `${V1_OPS_API_URL}/dashboard/inventory?cloud=${cloud}&service=${service}`;
  return from(AuthHttp.post(url, object));
}

export function getInventoryFailDetail(service, cloud, resourceType, object) {
  const url = `${V1_OPS_API_URL}/dashboard/inventory/fail?cloud=${cloud}&service=${service}&resourceType=${resourceType}`;
  return from(AuthHttp.post(url, object));
}

export function getTopChangedResources(object) {
  const url = `${V1_OPS_API_URL}/dashboard/inventory/top-changed-resources?number=4`;
  return from(AuthHttp.post(url, object));
}

export function getInvestigateFilter() {
  return from(AuthHttp.get(`${V1_OPS_API_URL}/filter/assetinventory`));
}

export function getInvestigate(body) {
  return from(
    AuthHttp.post(`${V1_OPS_API_URL}/policyengine/investigate`, body)
  );
}

export function getComplianceOverviewTrendAPI(object) {
  return from(
    AuthHttp.post(`${V1_OPS_API_URL}/compliance/overview/trend`, object)
  );
}

export function getComplianceOverviewDetailAPI(object) {
  return from(
    AuthHttp.post(`${V1_OPS_API_URL}/compliance/overview/detail`, object)
  );
}

export function getTop6InventoryAPI(object) {
  return from(
    AuthHttp.post(
      `${V1_OPS_API_URL}/compliance/overview/trend2?number=4`,
      object
    )
  );
}
